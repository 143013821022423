import React from 'react';
import { ResponsiveChoropleth } from '@nivo/geo';
import { geoFeatures } from "../Data/mockGeoFeatures";
import { useTheme } from "@mui/material";
import { mockGeographyData as data } from "../Data/mockData"
const GeographyChart = ({ isDashboard = false }) => {
    const theme = useTheme()
    return (
        <ResponsiveChoropleth
          data={data}
          theme={{
            axis: {
              domain: {
                line: {
                  stroke: theme.palette.grey[100],
                },
              },
              legend: {
                text: {
                  fill: theme.palette.grey[100],
                },
              },
              ticks: {
                line: {
                  stroke: theme.palette.grey[100],
                  strokeWidth: 1,
                },
                text: {
                  fill: theme.palette.grey[100],
                },
              },
            },
            legends: {
              text: {
                fill: theme.palette.grey[100],
              },
            },
          }}
          features={geoFeatures.features}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          domain={[0, 1000000]}
          unknownColor="#666666"
          label="properties.name"
          valueFormat=".2s"
          projectionScale={isDashboard ? 40 : 150}
          projectionTranslation={isDashboard ? [0.49, 0.6] : [0.5, 0.5]}
          projectionRotation={[0, 0, 0]}
          borderWidth={1.5}
          borderColor="#ffffff"
          legends={
            !isDashboard
              ? [
                  {
                    anchor: "bottom-left",
                    direction: "column",
                    justify: true,
                    translateX: 20,
                    translateY: -100,
                    itemsSpacing: 0,
                    itemWidth: 94,
                    itemHeight: 18,
                    itemDirection: "left-to-right",
                    itemTextColor: theme.palette.grey[100],
                    itemOpacity: 0.85,
                    symbolSize: 18,
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemTextColor: "#ffffff",
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]
              : undefined
          }
        />
      );
}

export default GeographyChart;